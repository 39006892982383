@font-face {
  font-family: "Reckless Neue TRIAL";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/RecklessNeueTRIAL-Light.woff2) format("woff2"),
    url(/src/assets/fonts/RecklessNeueTRIAL-Light.woff) format("woff");
}

/* All Satoshi fonts */
@font-face {
  font-family: "Satoshi";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Light.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Light.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-LightItalic.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-LightItalic.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Regular.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Regular.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Italic.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Italic.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Medium.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Medium.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-MediumItalic.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-MediumItalic.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Bold.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Bold.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-BoldItalic.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-Black.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-Black.woff) format("woff");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url(/src/assets/fonts/Satoshi-BlackItalic.woff2) format("woff2"),
    url(/src/assets/fonts/Satoshi-BlackItalic.woff) format("woff");
}
