@font-face {
  font-family: Reckless Neue TRIAL;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("RecklessNeueTRIAL-Light.32f54178.woff2") format("woff2"), url("RecklessNeueTRIAL-Light.9057eea9.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Light.7f02ab08.woff2") format("woff2"), url("Satoshi-Light.0a6976bf.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("Satoshi-LightItalic.38e58abd.woff2") format("woff2"), url("Satoshi-LightItalic.3dab2f7d.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("Satoshi-Italic.04615630.woff2") format("woff2"), url("Satoshi-Italic.7174094f.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("Satoshi-MediumItalic.1ce24771.woff2") format("woff2"), url("Satoshi-MediumItalic.29954c4f.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("Satoshi-BoldItalic.1cf08e80.woff2") format("woff2"), url("Satoshi-BoldItalic.0f1decd0.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Black.ae0b9de3.woff2") format("woff2"), url("Satoshi-Black.fb3487f7.woff") format("woff");
}

@font-face {
  font-family: Satoshi;
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("Satoshi-BlackItalic.c3b409a1.woff2") format("woff2"), url("Satoshi-BlackItalic.d22f02fc.woff") format("woff");
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-12 {
  bottom: 3rem;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-\[100px\] {
  top: 100px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[58px\] {
  height: 58px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.w-\[125px\] {
  width: 125px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[40px\] {
  width: 40px;
}

.w-full {
  width: 100%;
}

.max-w-\[1200px\] {
  max-width: 1200px;
}

.max-w-\[360px\] {
  max-width: 360px;
}

.flex-1 {
  flex: 1;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[120px\] {
  gap: 120px;
}

.gap-\[200px\] {
  gap: 200px;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.text-wrap {
  text-wrap: wrap;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-pink-500 {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position);
  --tw-gradient-to: #ec489900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-violet-500 {
  --tw-gradient-to: #8b5cf6 var(--tw-gradient-to-position);
}

.bg-clip-text {
  background-clip: text;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-\[100px\] {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pb-\[100px\] {
  padding-bottom: 100px;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-\[56px\] {
  padding-right: 56px;
}

.pt-\[180px\] {
  padding-top: 180px;
}

.text-center {
  text-align: center;
}

.font-reckless {
  font-family: Reckless Neue TRIAL, serif;
}

.font-satoshi {
  font-family: Satoshi, sans-serif;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-\[52px\] {
  font-size: 52px;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.leading-\[1\.1\] {
  line-height: 1.1;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.32\] {
  line-height: 1.32;
}

.text-\[\#101010\] {
  --tw-text-opacity: 1;
  color: rgb(16 16 16 / var(--tw-text-opacity));
}

.text-foreground {
  --tw-text-opacity: 1;
  color: rgb(255 255 230 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.opacity-60 {
  opacity: .6;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html, body {
  --tw-bg-opacity: 1;
  background-color: rgb(16 16 16 / var(--tw-bg-opacity));
  min-height: 100%;
}

html::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder\:text-foreground\/60::placeholder {
  color: #ffffe699;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (width >= 640px) {
  .sm\:h-\[46px\] {
    height: 46px;
  }

  .sm\:h-\[48px\] {
    height: 48px;
  }

  .sm\:w-\[48px\] {
    width: 48px;
  }

  .sm\:w-\[57px\] {
    width: 57px;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:text-\[102px\] {
    font-size: 102px;
  }

  .sm\:text-\[14px\] {
    font-size: 14px;
  }

  .sm\:text-\[24px\] {
    font-size: 24px;
  }

  .sm\:text-\[32px\] {
    font-size: 32px;
  }

  .sm\:text-\[40px\] {
    font-size: 40px;
  }
}

@media (width >= 768px) {
  .md\:w-\[360px\] {
    width: 360px;
  }

  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:text-\[112px\] {
    font-size: 112px;
  }

  .lg\:text-\[46px\] {
    font-size: 46px;
  }

  .lg\:leading-\[1\.2\] {
    line-height: 1.2;
  }
}

/*# sourceMappingURL=index.ad6dac1b.css.map */
